import React from 'react';
import Layout from '../components/layout';
import { CenteredImage, CenteredContentBlock } from '../components/styleBook';

const RentalPage = () => {
  return (
    <Layout>
      <section className="rental">
        <h1 id="tiedostojen-palautus">Tiedostojen palautus</h1>
        <CenteredContentBlock>
          Palautamme tiedostot rikkoutuneilta laitteilta. Voimme palauttaa myös
          vahingossa tuhottuja tiedostoja. <br />
          <br />
          Soita tai lähetä sähköpostia ja kysy palvelusta: 0500434040 tai
          myynti@timeimage.fi
        </CenteredContentBlock>

        <CenteredImage>
          <img
            src="/images/tiedostojen_palautus.jpg"
            alt="Tiedostojen palautus"
          />
        </CenteredImage>

        <CenteredContentBlock>
          <strong>Palatautamme tiedot seuraavista laitteista:</strong>
        </CenteredContentBlock>
        <CenteredContentBlock as="ul">
          <li>Tietokoneet</li>
          <li>Kannettavat</li>
          <li>Ulkoiset kovalevyt</li>
          <li>Sisäiset kovalevyt</li>
          <li>USB-muistitikut</li>
          <li>RAID-järjestelmät</li>
          <li>NAS-järjestelmät</li>
        </CenteredContentBlock>
        <CenteredContentBlock>
          Palautamme tiedostot Windows, Linux ja Mac -käyttöjärjestelmistä.
        </CenteredContentBlock>
        <CenteredContentBlock>
          Tiedostojen palautuksia on vaikea hinnoitella etukäteen, sillä
          jokainen tapaus on erilainen. Siksi aloitamme työn ilmaisella
          diagnoosilla etkä sitoudu maksamaan mitään! Me maksamme jopa laitteen
          postituksen Hämeenlinnaan laboratorioon vaikeissa tapauksissa, joita
          emme voi hoitaa Hämeentie 157 toimistolla.
          <br />
          <br />
          Jos tiedostoja ei saada palautettua, emme veloita mitään. Viallisen
          laitteen takaisinpostituksesta Hämeenlinnasta veloitetaan
          loppuasiakkaalta 60 euroa / käsittelykulut (sis. alv. 24%).
        </CenteredContentBlock>
      </section>
    </Layout>
  );
};

export default RentalPage;
